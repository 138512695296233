.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 80px auto;
    background: #f3f3f3;
    max-width: 480px;
    width: 100%;
    height: 100%;

}

.wrapper {
    /* padding: 20px 0 0 0; */
    margin: 40px 18px 10px 18px;
    /* background-color: rgb(101, 97, 97); */
    /* max-width: 375px; */
    /* width: 100%; */
    width: 100%;
    height: 100%;
    max-height: 812px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.img {
    width: 152px;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 22px;
    max-width: 330px;
    margin: 16px 0 24px 0;
    text-align: center;
    color: #000000;
}
.textAppVersion{
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 22px;
    max-width: 330px;
    margin: 0px 0 10px 0;
    text-align: center;
    color: #000000;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 339px; */
    height: 48px;
    background: #48484d;
    /* background: #FFFFFF; */
    /* opacity: 0.12; */
    backdrop-filter: blur(8px);
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
}

.textName {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

}

a {
    text-decoration: none;
    color: #FFFFFF;

}

.middle {
    color: #faad13;
}

.icons {
    display: flex;
    margin-top: 10px;
}

.iconSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #48484d;


    /* width: 40px;
    height: 40px; */
    border-radius: 4.44447px;
    color: white;

}

.facebook {
    margin: 0 32px;
}

.iconWidth {
    padding: 6px;
    width: 25px;
    height: 25px;
}

.others {
    padding-bottom: 15px;
    color: #6f6f6f;
    text-decoration: underline;
    cursor: pointer;
}